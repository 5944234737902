//Global styles

// fonts
@font-face {
    font-family: "ProximaNova";
    src: local("ProximaNova"), url(../fonts/ProximaNova-Regular.otf) format("truetype");
}

// colors by the design
$flame: #e3b770;
$flameScarlet: #cd212a;
$flameOrange: #fb8b23;
$moss: #92b24d;
$balaton: #00a66e;
$mossHover: #a7c167;
$sky: #008aad;
$flameHover: #c73012;
$gray: #7c7c7c;
$lightgray: #e5e5e5;
$darkgray: #484848;
$light: #f8f8f8;
$pink: #ffdbdb;
$salmon: #1a1a1a;
//Variables
$blue: #008aad;

html,
body,
#root {
    min-height: 100vh;
    overflow: auto;
    font-family: "ProximaNova";
    color: $darkgray;
}

// TITLES
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 700 !important;
}

// FORMS
form {
    label {
        font-weight: bold;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.3px;
        text-transform: uppercase;
        color: $gray;
    }
    input,
    .form-control {
        margin-bottom: 20px;
        background: $light;
        border: 1px solid $lightgray;
        border-radius: 8px;
        color: $gray;
        &::placeholder {
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.3px;
            color: $darkgray;
            opacity: 0.4;
        }
    }
}

.input-error {
    text-align: start;
    color: $flameScarlet !important;
}

// BUTTONS

button,
.btn,
.btn-primary {
    box-shadow: 0px 4px 12px #cbcbcb;
    border-radius: 8px;
    border: none !important;

    &.orange {
        background: $flame;
        color: #fff;
        a {
            color: #fff;
        }
        &:focus,
        &:active,
        &:hover {
            background-color: $flameHover;
            border: none;
            box-shadow: 0px 4px 12px #cbcbcb;
            color: #fff;
        }
    }

    &.green {
        background: $moss;
        &:focus,
        &:active,
        &:hover {
            background-color: $mossHover;
            border: none;
            box-shadow: 0px 4px 12px #cbcbcb;
        }
    }
}

.back {
    color: $flameHover;
    cursor: pointer;
    &:hover {
        color: $flameHover;
    }
}

// ICONS

.orange-icon-container {
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 50%;
    padding: 5px;
    background-color: $flame;
    color: #fff;
    width: 40px;
    height: 40px;
    margin: 5px;
}

.large-icon-container {
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 50%;
    padding: 15px;
    background-color: $pink;
    color: $flame;
    width: 110px;
    height: 110px;
    margin: 5px;
}

// TILES

.tile {
    flex-direction: row;
    align-items: center;
    padding: 10px 10px 10px 10px;
    height: 100%;
    background: #ffffff;
    box-shadow: 0px 0px 25px #e5e5e5;
    border-radius: 20px;
    padding: 30px;
}

.simple-tile {
    flex-direction: row;
    align-items: center;
    border-radius: 20px;
    overflow: hidden;
    height: 100%;
    margin-left: 6px;

    &.map-tile{
        min-height: 300px;
    }
}

.table-tile {
    flex-direction: row;
    align-items: center;
    background: #ffffff;
    box-shadow: 0px 0px 25px #e5e5e5;
    border-radius: 20px;
    overflow-x: auto;
}

.col,
div[class*="col-"] {
    margin-top: 20px !important;
    margin-bottom: 20px;
}

// TABS

.page-tabs {
    margin-bottom: 30px !important;
    border-bottom: none !important;

    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (min-width: 992px) {
        position: absolute;
        top: 16px;
        left: 200px;
    }

    .tabitem {
        box-shadow: none;
        color: $darkgray;
        font-size: 16px;
        padding: 16px;
        height: 36px;
        border-radius: 8px;
        margin: 0px 8px;
        display: flex;
        justify-content: center;
        align-items: center;

        &.active,
        &:hover {
            color: $flame !important;
            background-color: $light !important;
        }
    }
}

.btn-margin-fix {
    margin-left: 10px;
}

.divider-border {
    border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.divider{
    height: 1px;
    width: calc(100% - 10px);
    margin-left: 10px;
    background-color: rgba(0, 0, 0, 0.05);
}
